<template>
  <div>
    <VTitle :title="$t('app.licenses', 2)" class="route-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert v-else-if="rows.length === 0" :text="$t('app.no_results_found')" />

    <div v-else>
      <div class="grid grid-cols-1 gap-8">
        <div v-for="(license, index) in rows" :key="index">
          <License :license="license" />
        </div>
      </div>

      <FullPagination
        :pagination="pagination"
        @update:current_page="onUpdateCurrentPage"
        @update:per_page="onUpdatePerPage"
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
// Composables
import useRead from "@/composables/useRead";
// Components
import VTitle from "@/components/VTitle";
import FullPagination from "@/components/tables/FullPagination";
import License from "./License";
import VAlert from "@/components/VAlert";

export default {
  components: {
    VTitle,
    License,
    VAlert,
    FullPagination
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // CONSTANTS
    const documentTitle = `${t("app.licenses", 2)} - ${t(
      "app.administration"
    )}`;

    // CUSTOM COMPOSABLES
    const {
      pagination,
      rows,
      isLoading,
      onUpdateCurrentPage,
      onUpdatePerPage
    } = useRead({
      endpoint: "administration.licenses",
      relations: ["invoices"],
      filters: [],
      pagination
    });

    return {
      documentTitle,
      // useRead
      rows,
      isLoading,
      pagination,
      onUpdateCurrentPage,
      onUpdatePerPage
    };
  }
};
</script>
