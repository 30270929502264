<template>
  <div :id="id" class="dropdown ml-auto sm:ml-0">
    <VButton
      class="btn-primary dropdown-toggle"
      :class="buttonClass"
      aria-expanded="false"
      :label="label"
    />

    <div class="dropdown-menu w-40">
      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
        <div
          v-for="(option, index) in options"
          :key="index"
          class="flex items-center block p-2 cursor-pointer transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
          @click="onClick(option)"
        >
          <slot :option="option" :index="index">
            {{ option.text }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
// Components
import VButton from "@/components/VButton";

export default {
  components: {
    VButton
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    buttonClass: {
      type: String,
      default: ""
    }
  },
  emits: ["click"],
  setup(props, context) {
    // Inject
    const _ = inject("lodash");

    // Data
    const id = ref(_.uniqueId());

    // Methods
    const onClick = option => {
      cash(id).dropdown("hide");
      context.emit("click", option);
    };

    return {
      onClick,
      id
    };
  }
};
</script>
