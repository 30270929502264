<template>
  <VCard>
    <template #content>
      <div class="flex justify-between items-center">
        <div class="flex justify-between items-center">
          <LicenseLogo class="mr-4" />

          <div>
            <div class="font-medium text-base">
              {{ name }}
            </div>

            <div class="w-96 truncate text-sm text-gray-600">
              {{ key }}
            </div>
          </div>
        </div>

        <div>
          {{ details }}
        </div>

        <div>
          <div class="flex">
            <div class="colon text-gray-600">
              {{ $t("app.start_date") }}
            </div>

            <div>
              {{ startDate }}
            </div>
          </div>

          <div class="flex">
            <div class="colon text-gray-600">
              {{ $t("app.end_date") }}
            </div>

            <div>
              {{ expiryDate }}
            </div>
          </div>
        </div>

        <div>
          <Tippy
            tag="a"
            href="javascript:;"
            class="tooltip"
            :content="$t('app.sms_enabled')"
          >
            <VButton
              class="rounded-full h-10 w-10 mr-2 cursor-auto hover:bg-transparent"
              :class="{ 'btn-outline-primary': hasSMSEnabled }"
              icon="fa-sms fa-lg"
            />
          </Tippy>

          <Tippy
            tag="a"
            href="javascript:;"
            class="tooltip"
            :content="$t('app.email_enabled')"
          >
            <VButton
              class="rounded-full h-10 w-10 mr-2 cursor-auto hover:bg-transparent"
              :class="{ 'btn-outline-primary': hasEmailEnabled }"
              icon="fa-at fa-lg"
            />
          </Tippy>
        </div>
      </div>
    </template>

    <template #actions>
      <div>
        <ProgressBar :percentage="percentage">
          <template #label>
            <!-- eslint-disable-next-line -->
            <span v-html="
                $t('app.users_assigned', {
                  number: assignedUsers,
                  total: totalUsers
                })
              "
            />
          </template>
        </ProgressBar>

        <div class="flex justify-end mt-5">
          <VButton
            class="btn-primary mr-2"
            :label="$t('app.users', 2)"
            @click="onClickUsers"
          />

          <DropdownButton
            :label="$t('app.invoices', 1)"
            :options="invoices"
            @click="onClickInvoice"
          />
        </div>
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
import useDisplay from "@/composables/useDisplay";
// Components
import VCard from "@/components/VCard.vue";
import VButton from "@/components/VButton.vue";
import DropdownButton from "@/components/DropdownButton.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import LicenseLogo from "@/components/LicenseLogo.vue";

export default {
  components: {
    VCard,
    VButton,
    DropdownButton,
    ProgressBar,
    LicenseLogo
  },
  props: {
    license: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const { t } = useI18n();

    // Composables
    const { formatDate } = useDisplay();
    const { ALL_OPTIONS } = useOptions();

    // Computed
    const name = computed(() => props.license?.name ?? "");
    const key = computed(() => props.license?.key ?? "");
    const details = computed(() => props.license?.details ?? "");
    const startDate = computed(() =>
      formatDate({ date: props.license?.start_date })
    );
    const expiryDate = computed(() =>
      formatDate({
        date: props.license?.expiry_date,
        fallback: t("app.perpetual_license")
      })
    );
    const hasSMSEnabled = computed(
      () => props.license?.sms_notification_enabled === ALL_OPTIONS.YES.value
    );
    const hasEmailEnabled = computed(
      () => props.license?.email_notification_enabled === ALL_OPTIONS.YES.value
    );
    const assignedUsers = computed(
      () => props.license?.assigned_active_users ?? 0
    );
    const totalUsers = computed(() => props.license?.number_of_users ?? 0);
    const percentage = computed(() => {
      if (!assignedUsers.value || !totalUsers.value) {
        return 0;
      }

      const result = ((assignedUsers.value / totalUsers.value) * 100).toFixed(
        0
      );
      return result > 100 ? 100 : result;
    });
    const invoices = computed(() => {
      const invoices = props.license?.invoices ?? [];

      return invoices.map(invoice => ({ ...invoice, text: invoice.name }));
    });

    // Methods
    const onClickUsers = () => {
      router.push({
        name: "administration-license-users",
        params: { id: props.license?.id }
      });
    };

    const onClickInvoice = invoice => {
      router.push({
        name: "administration-license-invoice",
        params: {
          id: props.license?.id,
          invoice_id: invoice?.id
        }
      });
    };

    return {
      name,
      key,
      details,
      startDate,
      expiryDate,
      hasSMSEnabled,
      hasEmailEnabled,
      assignedUsers,
      totalUsers,
      percentage,
      invoices,
      onClickUsers,
      onClickInvoice
    };
  }
};
</script>
